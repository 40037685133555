<template>

  <div class="container">
		<div class="row" v-if="!edit">

      <div class="col-12 col-lg-9 subnav">
        <router-link to="/hallinta/sopimusasiakkaat"><h1>Sopimusasiakkaat</h1></router-link>
				<router-link to="/hallinta/kohteet"><h1>Kohteet</h1></router-link>
        <router-link to="/hallinta/asukkaat"><h1>Asukkaat</h1></router-link>
        <router-link to="/hallinta/huoltomiehet"><h1>Huoltomiehet</h1></router-link>
			</div>
			<div class="col-12 col-lg-3 text-right">
				<button class="btn btn-new btn-lg d-inline-block" v-if="!loading" v-on:click.stop.prevent="newitem"><i class="fas fa-plus"></i> Luo uusi huoltomies</button>
			</div>

			<div class="col-12">

				<b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

        <div class="filters">
          <div class="filter">
            <input type="text" class="form-control form-control-sm" v-model="filters.fullname" placeholder="Hae nimellä" />
          </div>
          <button type="button" class="btn btn-sm btn-primary" v-on:click="load()">Suodata</button>
				</div>

				<b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <table class="admin-list" v-if="!loading && visible > 0">
					<tr>
						<th>ID <i class="fas fa-chevron-down" v-on:click="sort = 'id:asc'" v-if="sort != 'id:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'id:desc'" v-if="sort == 'id:asc'"></i></th>
						<th>Nimi <i class="fas fa-chevron-down" v-on:click="sort = 'Profile.fullname:asc'" v-if="sort != 'Profile.fullname:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'Profile.fullname:desc'" v-if="sort == 'Profile.fullname:asc'"></i></th>
						<th></th>
						<th>Tila</th>
						<th></th>
					</tr>
					<tr v-for="(item) in items" :key="item.id" :id="['user-' + item.id]">
						<td class="w120"><p><strong>{{ item.id }}</strong></p></td>
						<td class=""><p><strong>{{ item.fullname }}</strong></p></td>
						<td class=""></td>
            <td class=""><p><span v-if="item.active" class="green">Aktiivinen</span><span v-if="!item.active" class="red">Ei aktiivinen</span></p></td>
            <td class="text-right"><a class="btn btn-sm btn-primary" v-on:click.stop.prevent="edititem(item.id)">Näytä</a></td>
					</tr>
				</table>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0? true : false">Käyttäjiä ei löydetty!</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

			</div>
		</div>
		<div class="row" v-if="edit">
      <div class="col-12 pt-1">
        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>
				<form @submit.prevent="saveedit">
          <div class="row">
						<div class="col-12 col-lg-6">
              <h1 v-if="typeof edit_data.id == 'undefined'">Uusi huoltomies</h1>
              <h1 v-if="typeof edit_data.id != 'undefined'">{{ edit_data.fullname }} ({{ edit_data.id }})</h1>

              <div class="row">

                <div class="form-group col-lg-6">
                  <label class="form-label">Sukunimi</label>
                  <input type="text" v-model="edit_data.lastname" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Etunimi</label>
                  <input type="text" v-model="edit_data.firstname" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Sähköpostiosoite (käyttäjätunnus)</label>
                  <input type="email" v-model="edit_data.email" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Puhelin</label>
                  <input type="text" v-model="edit_data.phone" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Aktiivinen</label>
                  <select v-model="edit_data.active" class="form-control">
                    <option value="true">Kyllä</option>
                    <option value="false">Ei</option>
                  </select>
                </div>

                <div class="form-group col-12">
                  <label class="form-label">Lisätiedot</label>
                  <textarea v-model="edit_data.comment" class="form-control"></textarea>
                </div>

                <div class="form-group col-12">
                  <p v-if="typeof edit_data.id != 'undefined'">
                    Tunnus luotu {{ edit_data.createdon | formatDateTime }}<br/>
                    <span v-if="edit_data.active && typeof edit_data.activated_by != 'undefined'">Aktivoinut {{ edit_data.activated_by }} {{ edit_data.activated_on | formatDateTime }}</span>
                  </p>
                </div>

              </div>

						</div>

            <div class="col-12 col-lg-5 offset-lg-1">
              <div class="form-group text-right">
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id != 'undefined'" v-on:click="canceledit()">TAKAISIN</button>
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id == 'undefined'" v-on:click="canceledit()">PERUUTA</button>
                <button type="submit" class="btn btn-new btn-lg ml-2"><i class="fas fa-save"></i> TALLENNA</button>
              </div>

            </div>

					</div>
				</form>

			</div>
		</div>
	</div>

</template>

<script>
module.exports = {
	data: function () {
		return {
			loading: true,
			error: false,
			items: [],
      sites: [],
      filters: {
        search: ''
      },
			edit: false,
			edit_data: {
				id: 0,
				fullname: '',
				active: false
			},
			sending: false,
			visible: 0,
			perpage: 10,
			pages: 0,
			page: 0,
			sort: 'id:desc'
		}
	},
	created: function () {
    var vm = this;
    vm.load();
    if(typeof vm.$route.query.id != 'undefined') {
			vm.edititem(this.$route.query.id);
		}
	},
	methods: {
		load: function () {
			var vm = this;
      vm.error = false;
			vm.loading = true;
			var sort = vm.sort.split(':');
			this.axios.get('/api/users?type=employees&limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sort=' + sort[0] + '&dir=' + sort[1] + '&filters=' + JSON.stringify(vm.filters))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
					vm.items = response.data.results;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
    displayAddress: function(addr) {
      return addr.split(",").join("<br/>");
    },
		showpage: function (id) {
			this.page = id;
		},
		edititem: function (id) {
			var vm = this;
			vm.edit = true;
      vm.sending = true;

      this.axios.get('/api/users/' + id)
      .then(function (response) {
        if(response.data.success) {
          vm.edit_data = response.data.object;
          vm.edit_data.firstname  = response.data.object.extended.firstname;
          vm.edit_data.lastname  = response.data.object.extended.lastname;
          vm.edit_data.activated_by = response.data.object.extended.activated_by_name;
          vm.edit_data.activated_on = response.data.object.extended.activated_on;
          vm.sending = false;
        } else {
          vm.$parent.makeToast('danger', 'Huoltomiehen lataus', 'Epäonnistui!');
          vm.edit = false;
          vm.sending = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.$parent.makeToast('danger', 'Huoltomiehen lataus', 'Epäonnistui!');
        vm.edit = false;
        vm.sending = false;
      })
		},
		canceledit: function () {
			this.edit = false;
		},
		newitem: function () {
			this.edit = true;
			this.edit_data = { fax:'employee', terms: true, active: true };
		},
		saveedit: function () {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				if(typeof vm.edit_data.id == 'undefined') method = 'post';
				this.axios({
					method: method,
					url: '/api/users',
					data: vm.edit_data
				})
				.then(function (response) {
					if(response.data.success) {
						if(typeof vm.edit_data.id == 'undefined' && response.data.object.id > 0) vm.edit_data.id = response.data.object.id;
            vm.edit_data.fullname = response.data.object.fullname;

						vm.$parent.makeToast('success', 'Käyttäjän tallennus', 'Onnistui!');
						vm.load();
					} else {
            var tmp = []
						Object.keys(response.data.errors).forEach(function(key){ tmp.push(response.data.errors[key]); });
						vm.$parent.makeToast('warning', 'Käyttäjän tallennus', tmp.join(" ", tmp));
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Käyttäjän tallennus', 'Epäonnistui!');
				})
			}
		}
	},
	watch: {
		page: function () {
			this.load();
		},
    sort: function () {
			this.load();
		},
	}
}
</script>
